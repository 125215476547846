.sidebar-account-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    gap: 15px;
    padding: 20px;
    border-radius: 16px;
    margin-top: 300px;
    background: var(--color-blue-1);
    /*box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;*/
}

.sidebar-account-status-title {
    font-size: 19px;
}

.sidebar-account-status-text {
    font-size: 14px;
    color: #858585;
}

.sidebar-account-status svg {
    width: 30px;
    height: 30px;
}