.card-container {
    display: flex;
    gap: 20px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    margin: 0 auto;
    width: 352px;
}

.card-main-container {
    display: flex;
    flex-direction: column;
}

.card-container-mobile {
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-content: center;
    overflow-x: hidden;
    width: auto;
    max-width: 380px;
    will-change: transform;
}

.card-container-mobile-swipe {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
}

.card-container-mobile > div {
    /*margin: 0 auto;*/
}

.card-mobile-wrap {
    scroll-snap-align: start;
    padding: 5px 10px 30px 10px;
}

.card {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 352px;
    /*min-width: 352px;*/
    height: 223px;
    border-radius: 16px;
    padding: 25px 20px;
    justify-content: space-between;
    color: var(--color-gray-0);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.card-order {
    cursor: initial;
    align-items: flex-end;
}

.card-mini {
    width: 94px;
    height: 60px;
    border-radius: 8px;
    margin-top: 6px;
    margin-right: 15px;
    text-align: right;
    padding: 3px 8px;
}

.card-mini-container {
    border-radius: 8px;
    border: 1px solid var(--color-gray-5);
    padding: 20px 12px;
}

.card-plastic {
    background: linear-gradient(to right top, #833ea0, #28abe2);
}

.card-metal {
    background: linear-gradient(to right top, #5d5d5d, #bebebe);
}

.card-order:hover {
    left: 0 !important;
    top: 0 !important;
}

.card-mini > svg {
    max-width: 30px;
}

.card:hover, .card-empty:hover {
    left: -1px;
    top: -1px;
    box-shadow: rgba(0, 0, 0, 0.19) 2px 10px 20px, rgba(0, 0, 0, 0.23) 2px 8px 8px;
}

.card-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    width: 352px;
    min-width: 352px;
    height: 223px;
    border-radius: 16px;
    padding: 25px 20px;
    color: var(--color-gray-24);
    background: var(--color-gray-2);
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    scroll-snap-align: start;
}

.card-empty-content {
    display: flex;
    align-items: center;
    gap: 40px;
}

.card-empty-content-title {
    font-size: 30px;
    color: var(--color-gray-20);
}

.card-empty-content-description {
    font-size: 14px;
    color: var(--color-gray-8);
}

.card-balance-container {
    display: flex;
    align-items: center;
    font-size: 28px;
    gap: 10px;
}

.card-balance-container svg > path {
    stroke: var(--color-white);
}

.card-balance-container > div > svg > {
    cursor: pointer;
}

.card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-number {
    font-size: 20px;
    /*text-shadow: 1px 1px 1px #000;*/
}

.card-center {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-footer {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.card-cardholder-label {
    font-size: 10px;
}

.card-cardholder-name {
    margin-top: 10px;
    font-size: 14px;
}

.card-expiry-label {
    font-size: 10px;
}

.card-expiry-date {
    margin-top: 10px;
    font-size: 14px;
}


.card-inactive {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-gray-24);
    background: var(--color-white);
    opacity: 50%;
    top: 0;
    left: 0;
    z-index: 0;
}

.card-inactive-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    color: var(--color-gray-24);
}

.card-inner {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.card-inner-item {
    padding: 5px 15px;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--color-gray-0);
}

.card-inner-item-title {
    font-size: 12px;
    font-weight: 700;
}

.card-status-container {
    display: flex;
    justify-content: right;
}

.card-functions-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    min-width: 275px;
    gap: 110px;
}

.card-functions-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.card-current-balance-label {
    font-size: 20px;
    /*color: var(--color-gray-17);*/
}

.card-current-balance- {
    font-size: 24px;
    color: var(--color-blue-5);
}

.card-functions-item {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding: 12px 16px;
    color: var(--color-blue-8);
    border: 1px solid var(--color-blue-8);
    border-radius: 50px;
    cursor: pointer;
    width: fit-content;
}

.card-order-progress-container {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    margin-top: 55px;
    color: var(--color-gray-7);
}

.card-order-progress {
    background: var(--color-gray-2);
    width: 100%;
    height: 10px;
    border-radius: 42px;
}

.card-order-progress-filled {
    background: linear-gradient(to right top, #833ea0, #28abe2);
    height: 10px;
    border-radius: 42px;
    width: 1%;
    transition: width 1s;
}

.card-order-progress-step {
    text-align: center;
    font-size: 12px;
}

.card-order-progress-step-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-order-progress-step-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.label-gray {
    color: var(--color-gray-7);
}

.label-blue {
    color: var(--color-blue-5);
}

.label-orange {
    color: var(--color-primary-5);
}

.label-green {
    color: var(--color-green-5);
}

.label-red {
    color: var(--color-red-5);
}

.card-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-actions-container {
    width: 100%;
    color: var(--color-blue-5);
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 40px;
    justify-content: flex-end;
}

.card-actions-container > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

.card-actions-container-mobile {
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    right: 45px;
    width: 0;
    color: transparent;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
}

.card-actions-container-mobile-active {
    color: var(--color-blue-5);
    background: white;
    width: 225px;
    border-radius: 16px;
    z-index: 10000;
    padding: 10px 20px;
    transition: all .25s ease-in-out;
    box-shadow: 0 4px 24px rgba(68, 74, 109, 0.25);
}

.card-actions-container-mobile > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.card-actions-container-mobile svg {
    width: 40px;
    height: 40px;
}

.icon-more-down {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*background: var(--color-blue-1);*/
}

.icon-more-down > svg {
    width: 30px;
}

.icon-more-down-active > svg {
    transform: rotate(90deg);
}

.icon-more-down-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 352px;
    /*margin-top: 10px !important;*/
    /*margin-bottom: 5px !important;*/
}