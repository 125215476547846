.landing-card-first-page {
    background: linear-gradient(#29255f, #505f6d);
    color: var(--color-white);
    width: 100%;
    min-height: 100vh;
    height: auto;
    scroll-behavior: smooth;
}

.landing-card-header {
    padding-left: 2rem;
    padding-right: 2rem;
}

.landing-card-first-page-content {
    width: 90%;
    max-width: 80rem;
    border: 1px solid black;
}

.landing-card-logo {
    background: url("../../../public/img/logo-white.svg") no-repeat;
    background-size: contain;
    min-width: 250px;
    width: 250px;
    height: 39px;
}

.landing-card-second-page {
    background: var(--color-gray-1);
    width: 100%;
    scroll-behavior: smooth;
    color: var(--color-gray-24);
    padding: 2rem 0 2rem 0;
}

.landing-card-second-page-inner1-mobile {
    display: grid;
    grid-template-rows: minmax(40px, auto) minmax(40px, auto) minmax(40px, 5rem)  minmax(15px, 3rem);
    padding: 2.5rem;
    min-height: 100vh;
}

.landing-card-second-page-inner2-mobile {
    padding: 2.5rem;
}

.landing-card-second-page-block1 {
    position: relative;
    background: linear-gradient(180deg, #0046FF 0%, rgba(0, 70, 255, 0.50) 100%) !important;
}

.landing-earth {
    position: absolute;
    bottom: 0;
    right: 0;
    background: url("../../images/landing/earth.svg") no-repeat;
    background-size: contain;
    background-position: bottom;
    width: 300px;
    max-width: 90%;
    height: 300px;
}

.landing-li-active {
    border-radius: 8px;
    border: 1px solid var(--color-blue-1);
}

.landing-cards-ul > li:not(:last-child) {
    margin-bottom: 1rem;
    letter-spacing: 0.48px;
}

.landing-cards-ul-2 > li:not(:last-child) {
    margin-bottom: 1rem;
    letter-spacing: 0.48px;
}

.landing-cards-block {
    border-radius: 20px;
    background: var(--color-white);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.landing-cards-block-1 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 80px;
    grid-row-gap: 46px;
    padding: 40px 50px;
}

.landing-cards-block-1 > div:nth-child(1) { grid-area: 1 / 1 / 2 / 2; }
.landing-cards-block-1 > div:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
.landing-cards-block-1 > div:nth-child(3) { grid-area: 2 / 1 / 3 / 3; }

.landing-cards-block-2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    color: var(--color-gray-19);
}

@media screen and (max-width: 768px) {
    .landing-cards-block-2 {
        display: flex;
        flex-direction: column;
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.landing-cards-block-2 > div {
    padding: 30px !important;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    min-height: 470px;
}

@media screen and (max-width: 800px) {
    .landing-cards-block-2 > div {
        width: 320px;
        margin: 0 auto;
    }
}

.landing-cards-block-2 > div:nth-child(2) {
    background: var(--color-yellow-4) !important;
}

.landing-cards-block-2 > div:nth-child(3) {
    background: var(--color-gray-4) !important;
}

.landing-cards-footer {
    padding: 60px 2.5rem 30px 2.5rem;
    color: var(--color-white);
    background: linear-gradient(#29255f, #505f6d);
}

.landing-cards-footer-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.875rem;
}

.landing-cards-footer-menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    gap: 5.3125rem;
    font-size: 0.875rem;
    font-weight: 300;
}

@media screen and (max-width: 768px) {
    .landing-cards-footer-menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.landing-cards-footer-menu > div {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
}

.landing-cards-footer-menu > div > div:not(:first-child) {
    cursor: pointer;
}

.landing-cards-footer-menu > div > div:not(:first-child):hover {
    text-decoration: underline;
}



.landing-cards-footer-icons {
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 2.5rem;
}

.landing-cards-footer-icons svg {
    width: 1.875rem;
    height: 1.875rem;
    cursor: pointer;
}

.landing-card-mobile-block {
    border-radius: 8px;
    background: var(--color-blue-5);
    color: var(--color-white);
    padding: 20px;
}

.landing-cards-center {
    min-width: 1024px;
}

.lading-cards-grid {
    grid-template-columns: minmax(125px, auto) minmax(100px, 1fr) minmax(125px, auto);
}

.landing-card-coins {
    display: grid;
    grid-template-columns: repeat(5, auto);
    margin-top: 3rem;
}