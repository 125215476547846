.mobile-login-entry-point-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    width: 100%;
    height: 100%;
    color: var(--color-gray-15);
}

.mobile-login-entry-point-container > div > h4 {
    margin-bottom: 24px;
    color: var(--color-gray-24);
}

.mobile-login-entry-point-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 17px;
    width: 100%;
    margin-top: 30px;
}

.auth-button-container {
    margin-top: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    width: 100%;
}

.auth-title1 {
    color: var(--color-gray-24);
    margin-bottom: 24px;
    text-align: left;
    width: 100%;
}

.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.auth-container > h3 {
    color: var(--color-gray-24);
}

.auth-container > form {
    width: 500px;
}

.auth-description {
    text-align: center;
    font-size: 1rem;
    line-height: 28px;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 90px;
    max-width: 621px;
}

@media screen and (max-width: 768px) {
    .auth-description {
        margin-top: 0.5rem;
        margin-bottom: 2rem;
    }
}

.auth-hint {
    margin-top: -15px;
    margin-bottom: 18px;
    margin-left: 16px;
    color: var(--color-gray-12);
}

.auth-terms {
    display: flex;
    gap: 19px;
    color: var(--color-gray-12);
}