.sidebar {
    width: 265px;
    padding: 36px 20px;
    background: var(--color-white);
    display: grid;
    grid-template-rows: minmax(29px, 90px) minmax(100px, 1fr) minmax(15px, auto);
}


@media screen and (max-width: 1440px){
    .sidebar {
        width: 215px;
    }
}

@media screen and (max-width: 1024px){
    .sidebar {
        width: auto;
        padding: 36px 10px;
    }
}

.sidebar-logo {
    width: 185px;
    height: 29px;
    background: url("../../images/logo.png") no-repeat center;
    background-size: contain;
    cursor: pointer;
}

.sidebar-menu {
    display: flex;
    flex-direction: column;
    gap: 40px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    min-width: 0;
}

@media screen and (max-width: 1024px){
    .sidebar-menu {
        width: auto;
    }
}

.sidebar-menu > div > svg {
    width: 24px;
    height: 24px;
}

.sidebar-menu-item {
    width: 225px;
    height: 46px;
    display: flex;
    gap: 24px;
    padding: 11px 15px;
    color: var(--color-neutrals-4);
    cursor: pointer;
    border-radius: 5px;
}

@media screen and (max-width: 1024px){
    .sidebar-menu-item {
        width: auto;
    }
}

.sidebar-menu-item-active {
    background: var(--color-blue-1);
    color: var(--color-blue-6);
}

.sidebar-menu-item-active > svg > path {
    fill: #4C70D0;
}

.sidebar-menu-item-active > svg > g > path {
      stroke: #4C70D0;
}

.sidebar-copyright {
    font-size: 12px;
    color: var(--color-neutrals-4);
    text-align: center;
}