.invite-logo {
    margin: 0 auto;
}

.invite-logo svg{
    max-width: 150px;
    max-height: 150px;
}

.invite-description {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    color: var(--color-neutrals-4);
    margin-bottom: 50px;
}

.invite-description-helper {
    font-size: 14px;
    font-weight: 300;
}

.invite-description-helper {
    font-size: 14px;
    text-align: center;
    color: var(--color-gray-8);
}