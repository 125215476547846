.wallet-layout {
    display: grid;
    gap: 24px;;
    max-width: fit-content;
    min-width: 1100px;
}

.wallet-layout-inner {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    grid-template-areas: "sidebar content";
    gap: 24px;;
}

.wallets-container  {
    display: flex;
    gap: 24px;;
    width: 100%;
    overflow: scroll;
    position: relative;
    left: -5px;
    padding-left: 5px;
    padding-bottom: 8px;
}

.wallet-info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    min-width: fit-content;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.wallet-info svg {
    width: 40px;
    height: 40px;
}

.wallet-balance {
    font-size: 18px;
}

.wallet-info-inner {
    display: flex;
    align-items: center;
    gap: 8px;
}

.wallet-left-column {
    display: flex;
    flex-direction: column;
    gap: 24px;;
    grid-area: sidebar;
}

.wallet-right-column {
    display: flex;
    flex-direction: column;
    gap: 24px;;
    grid-area: content;
    width: 465px;
}

.wallet-cards-container {
    display: flex;
    height: 235px;
    position: relative;
    width: 700px;
    margin-bottom: 80px;
}

.wallet-cards-container:nth-child(2)  {
    left: 150px;
}

.icon-settings {
    position: absolute;
    left: 580px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-radius: 100%;
}

.icon-settings > svg {
    width: 40px;
    height: 40px;
    animation: rotation 30s infinite linear;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.cards-carousel-item {
    position: absolute;
}

.cards-carousel-item:nth-child(2) {
    left: 215px;
    top: 7px;
}

.cards-carousel-animation-init-item-1 {
    animation-duration: .5s;
    animation-name: init-first;
}

@keyframes init-first {
    from {
        top: -100px;
        left: -100px;
    }

    to {
        top: 0;
        left: 0;
    }
}

.cards-carousel-animation-init-item-2 {
    animation-duration: .5s;
    animation-name: init-second;
}

@keyframes init-second {
    from {
        top: -100px;
        left: -100px;
    }

    to {
        top: 7px;
        left: 215px;
    }
}

.cards-carousel-animation-item-1 {
    animation-duration: .5s;
    animation-name: first;
}

@keyframes first {
    from {
        left: 0;
    }

    50% {
        left: -170px;
    }

    to {
        left: 0;
    }
}

.cards-carousel-animation-item-2 {
    animation-duration: .5s;
    animation-name: second;
}

@keyframes second {
    from {
        left: 215px;
    }

    50% {
        left: 370px;
    }

    to {
        left: 215px;
    }
}


.cards-carousel-animation-mobile-item-1 {
    z-index: 1;
    animation-duration: .5s;
    animation-name: first-mobile;
}

@keyframes first-mobile {
    from {
        left: -720px;
    }

    to {
        left: 0;
    }
}

.cards-carousel-animation-mobile-item-2 {
    z-index: -1;
    animation-duration: 1s;
    animation-name: second-mobile;
}

@keyframes second-mobile {
    from {
        left: -800px;
    }

    to {
        left: 0;
    }
}

.wallet-mobile-swipe-icon-container {
    position: absolute;
    left: 330px;
    top: 276px;
    /*opacity: 0.1;*/
    animation-duration: 3s;
    animation-delay: 1s;
    animation-iteration-count: 3;
    animation-name: swipe;
}

.wallet-mobile-swipe-icon-container > svg {
    width: 20px;
    height: 20px;
}

.wallet-mobile-swipe-icon-container > svg, .wallet-mobile-swipe-icon-container > svg > path, .wallet-mobile-swipe-icon-container > svg > g > g > path{
    fill: var(--color-gray-7);
}

@keyframes swipe {
    from {
        left: 330px;
    }

    10% {
        left: 325px;
    }

    20% {
        left: 330px;
    }

    to {
        left: 330px;
    }
}

.wallet-header-mobile {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(28px, 30px) minmax(70px, 1fr) minmax(50px, auto);
    align-items: center;
    gap: 10px;
    height: 70px;
    padding: 10px;
    color: var(--color-gray-24);
}

.wallet-header-mobile-icons-right {
    display: flex;
    align-items: center;
    gap: 10px;
}


.wallet-header-mobile-name {
    font-size: 18px;
}

.wallet-header-mobile svg {
    width: 28px;
    height: 28px;
}

.wallet-header-mobile svg, .wallet-header-mobile > svg > g > path  {
    fill :var(--color-neutrals-4);
}

.wallet-header-mobile-icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 38px;
}

.wallet-header-mobile-icons-container > svg {
    cursor: pointer;
}

.wallet-header-mobile-text-container {
    text-align: center;
    margin-top: 4px;
    margin-bottom: 30px;
}

.wallet-header-mobile-text-container > h5:first-child {
    margin-bottom: 10px;
}

.wallet-header-mobile-balance {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: var(--color-blue-8);
}

.wallet-crypto-balance-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 4px;
}

.wallet-crypto-balance-container > div:nth-child(1) {
    font-size: 18px;
}
.wallet-crypto-balance-container > div:nth-child(2) {
    font-size: 14px;
    color: var(--color-gray-12);
}

.wallet-mobile-icons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 300px;
    margin: 0 auto;
}

.wallet-icons-container {
    font-size: 12px;
    gap: 20px;
    align-items: flex-end;
}


.wallet-icons-container > div:nth-child(3) > svg {
    width: 20px;
    height: 20px;
}

.wallet-mobile-icon-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
    transition: all 0.3s ease 0s;
}

.wallet-mobile-icon-item:hover {
    opacity: 0.8;
}

.wallet-mobile-icon-item-green {
    color: var(--color-green-5);
}

.wallet-mobile-icon-item-blue {
    color: var(--color-blue-5);
}

.wallet-mobile-icon-item-orange {
    color: var(--color-primary-5);
}

.wallet-mobile-card-items-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 0 10px;
}

.wallet-mobile-card-item {
    padding: 12px 16px;
    background: var(--color-white);
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
}

.wallet-mobile-card-item-icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.send-request-items svg {
    width: 40px;
    height: 40px;
}

.transaction-item-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.transactions-item {
    display: grid;
    grid-template-columns: minmax(50px, 60px) minmax(70px, 1fr) minmax(10px, auto);
    align-items: center;
    cursor: pointer;
}

.transactions-item > svg {
    width: 45px;
    height: 45px;
}

.transactions-item-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.transactions-item-date {
    font-size: 12px;
    color: var(--color-gray-12);
}

.transactions-item-amount-in {
    color: var(--color-green-5);
    font-weight: 500;
}

.transactions-item-amount-out {
    color: var(--color-red-5);
    font-weight: 500;
}