.top-panel {
    height: 48px;
    display: grid;
    grid-template-columns: 1fr auto;
    max-width: var(--content-width);
    margin: 0 auto;
}

.top-panel-left {
    display: flex;
    align-items: center;
    font-size: 32px;
    color: var(--color-gray-24);
}

.top-panel-right {
    position: relative;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 70px;
    justify-content: end;
}

.top-panel-icons-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
    width: fit-content;
    width: -moz-fit-content;
}

.top-panel-status-container {
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;
    width: fit-content;
    width: -moz-fit-content;
    color: var(--color-gray-17);
    /* font-size: 14px; */
    align-items: center;
}

.top-panel-icons-container svg {
    cursor: pointer;
    width: 32px;
    height: 32px;
}

.top-panel-icons-container svg, .top-panel-icons-container svg > path, .top-panel-icons-container svg > path > g  {
    fill: var(--color-neutrals-4);
}

.top-panel-email {
    color: var(--color-gray-7);
}

.top-panel-account-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

