.drawer-notifications-container {
    display: flex;
    flex-direction: column;
}

.drawer-notifications-item {
    display: flex;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-gray-4);
    cursor: pointer;
}

.drawer-notifications-item:hover {
    background: var(--color-blue-1);
}

.drawer-notifications-item-unread {
    background: var(--color-gray-1);
}

.drawer-notification-status {
    /*width: 8px;*/
}

.drawer-notification-item-content {
    display: flex;
    flex-direction: column;
}

.drawer-notification-item-content > div:nth-child(1) {
    font-size: 14px;
    font-weight: 500;
}

.drawer-notification-item-content > div:nth-child(2) {
    font-size: 12px;
    margin-top: 2px;
}

.drawer-notification-item-content > div:nth-child(3) {
    font-size: 12px;
    color: var(--color-gray-12);
    margin-top: 8px;
}

.notifications-drawer {
    right: -280px;
    left: unset;
    border-radius: 16px 0 0 0;
    background: var(--color-white);
}

.notifications-drawer-enabled {
    right: 0;
    padding: 20px 0;
    overflow: scroll;
}

.notifications-drawer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    font-size: 20px;
    padding: 0 15px;
}

.notifications-drawer-header svg {
    cursor: pointer;
}

.notifications-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    font-size: 14px;
    height: 50%;
}

.notifications-empty-title {
    font-size: 18px;
    font-weight: 600;
}

.notifications-empty > div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

.mark-read {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    font-size: 16px;
    color: var(--color-blue-5);
}