.notification-icon {
    position: relative;
}

.unread-counter {
    border-radius: 100%;
    width: 18px;
    height: 18px;
    background: var(--color-red-5);
    color: var(--color-white);
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -3px;
    left: -2px;
    cursor: pointer;
}