.mobile-button {
    height: 24px;
    border-radius: 24px;
    padding: 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    color: var(--color-gray-24);
    border: 1px solid var(--color-gray-24);
    cursor: pointer;
}

.mobile-button-gray {
    color: var(--color-gray-7);
    border: 1px solid var(--color-gray-7);
}

.mobile-button-blue {
    color: var(--color-blue-5);
    border: 1px solid var(--color-blue-5);
}

.mobile-button-blue-fill {
    color: var(--color-white);
    background: var(--color-blue-5);
    border: 1px solid var(--color-blue-5);
}

.mobile-button-red-fill {
    color: var(--color-white);
    background: var(--color-red-5);
    border: 1px solid var(--color-red-5);
}

.mobile-button-orange-fill {
    color: var(--color-white);
    background: var(--color-primary-5);
    border: 1px solid var(--color-primary-5);
}

.mobile-button-orange {
    color: var(--color-primary-5);
    border: 1px solid var(--color-primary-5);
}

.mobile-button-green {
    color: var(--color-green-5);
    border: 1px solid var(--color-green-5);
}

.mobile-button-red {
    color: var(--color-red-5);
    border: 1px solid var(--color-red-5);
}