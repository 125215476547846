.dashboard-layout {
    display: flex;
    min-height: 100%;
    position: relative;
    overflow: hidden;
    background: var(--color-gray-0);
}

@media screen and (max-width: 768px) {
    .dashboard-layout {
        max-width: 100%;
    }
}

.dashboard-content-container {
    padding: 30px 23px 30px 50px;
    width: 100%;
    overflow-x: scroll;
}

.dashboard-content {
    max-width: var(--content-width);
    margin: 37px auto 0;
    padding-bottom: 50px;
}

.dashboard-layout-mobile {
    position: relative;
    overflow: hidden;
    min-height: 100%;
    padding-bottom: 90px;
    background: var(--color-gray-0);
}

