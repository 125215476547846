.modal-bg {
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.modal {
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(68, 74, 109, 0.1);
    border-radius: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: fixed;
    z-index: 101;
    outline: none;
    height: auto;
    padding: 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    line-height: 22px;
    max-width: 90%;
}

@media screen and (max-width: 768px){
    .modal {
        min-width: 98%;
    }
}


.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.modal-close-button {
    background: url("../../images/icons/close.svg");
    height: 20px;
    width: 20px;
    cursor: pointer;
}

.modal-title {
    font-weight: 600;
    font-size: 24px;
}

.modal-description {
    font-weight: 400;
    font-size: 15px;
}

.modal-description-otp {
    color: var(--color-gray-24);
}

.modal-button-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.full-modal {
    display: flex;
    color: var(--color-gray-24);
    background: var(--color-white);
    box-shadow: 0 4px 4px rgba(68, 74, 109, 0.1);
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    position: absolute;
    z-index: 101;
    outline: none;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
}

.full-modal-left {
    width: 30rem;
    height: 100%;
    max-width: 35%;
    padding: 40px 70px;
    overflow-y: scroll;
    background: var(--color-gray-0);
}

.full-modal-left > h5 {
    margin-bottom: 100px;
}

.full-modal-right {
    width: 940px;
    padding: 100px 85px;
    overflow-y: scroll;
    margin: 0 auto;
}

.full-modal-close-button {
    background: url("../../images/icons/close.svg");
    height: 24px;
    width: 24px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
}