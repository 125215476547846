.profile-dropdown {
    position: absolute;
    background: white;
    width: 225px;
    border-radius: 16px;
    z-index: 10000;
    padding: 20px 10px;
    top: 45px;
    right: 0;
    transition: all .25s ease-in-out;
    box-shadow: 0 4px 24px rgba(68, 74, 109, 0.25);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.profile-dropdown-item {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
}

.profile-dropdown-item:hover {
    color: var(--color-blue-6);
    background: var(--color-blue-1);
}

.profile-dropdown-item svg {
    width: 20px;
    height: 20px;
}

.profile-dropdown-item > svg, .profile-dropdown-item > svg > g > path {
    fill: var(--color-neutrals-4);
}