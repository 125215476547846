.button {
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    width: fit-content;
    border-radius: 8px;
    height: 66px;
    white-space: nowrap;
}

.button > div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-fullwidth {
    width: 100%;
}

.button-type-primary {
    color: var(--color-white);
    background: var(--color-blue-5);
}

.button-type-primary-landing {
    color: var(--color-white);
    background: var(--color-blue-2);
}

.button-type-secondary-landing {
    color: var(--color-gray-20);
    background: var(--color-gray-0);
    border: 1px solid var(--color-gray-20);
}

.button-type-primary-gradient {
    color: var(--color-white);
    background: linear-gradient(to right, #6563b7, #28abe2);
}

.button-type-secondary {
    color: var(--color-blue-5);
    background: inherit;
    border: 1px solid var(--color-blue-5);
}

.button-type-inactive {
    color: var(--color-gray-3);
    background: var(--color-gray-1);
}

.button-type-white {
    color: var(--color-gray-20);
    background: var(--color-gray-0);
    border: 1px solid var(--color-gray-20);
}


.button-size-small {
    font-weight: 500;
    font-size: 13px;
    height: 36px;
    padding: 0.5rem 1rem;
}

.button-size-medium {
    font-size: 14px;
    height: 42px;
    font-weight: 400;
    line-height: 24px;
    padding: 0.75rem 2.6rem;
}

.button-size-large {
    font-weight: 600;
    font-size: 15px;
    height: 56px;
    padding: 1rem 2.6rem;
}

.button-space-between {
    justify-content: space-between;
}