.modal-deposit {
    width: 430px;
}

.modal-deposit-order {
    width: 560px;
}

.modal-deposit-order-crypto-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
}

.modal-deposit-order-crypto-container > div:nth-child(1) {
    font-size: 28px;
}

.modal-deposit-done svg {
    max-width: 156px;
    max-height: 156px;
}