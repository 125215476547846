* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 100%;
}

html {
    font-size: 16px;
    height: 100%;
}

@media screen and (max-width: 1280px) {
    html {
        font-size: 15px;
    }
}

@media screen and (max-width: 1024px) {
    html {
        font-size: 14px;
    }
}

html, body {
    height: 100%;
    min-height: 100%;
}

body {
    margin: 0;
    font-family: "Roboto", sans-serif;
    background: var(--color-white);
}

@media screen and (max-width: 768px) {
    body {
        font-size: 14px;
    }
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Medium.ttf);
    font-weight: 500;
}

div {
    box-sizing: border-box;
}

h3 {
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px;
}

h4 {
    font-size: 34px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
}

h5 {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

h6 {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

#root {
    height: 100%;
}

:root {
    --content-width: 1440px;

    --color-white: #FFFFFF;
    --color-blue-1: #DCE8FC;
    --color-blue-2: #56A8DC;
    --color-blue-4: #7697E2;
    --color-blue-5: #4C70D0;
    --color-blue-6: #3755B2;
    --color-blue-8: #182A78;
    --color-red-1: #ffe8f2;
    --color-red-5: #F65;
    --color-yellow-4: #FFD775;
    --color-primary-5: #F38337;
    --color-gray-0: #FAFAFA;
    --color-gray-1: #F5F5F5;
    --color-gray-2: #EBEBEB;
    --color-gray-3: #E0E0E0;
    --color-gray-4: #D6D6D6;
    --color-gray-5: #CCCCCC;
    --color-gray-7: #B8B8B8;
    --color-gray-8: #ADADAD;
    --color-gray-12: #858585;
    --color-gray-15: #666;
    --color-gray-17: #525252;
    --color-gray-19: #3D3D3D;
    --color-gray-20: #333;
    --color-gray-24: #0A0A0A;
    --color-green-1: #ebfbf1;
    --color-green-5: #53AE57;
    --color-neutrals-4: #444A6D;
}

.paragraph {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.paragraph-medium {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.paragraph-small {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.subtitle {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.subtitle1 {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
}

.title1 {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
}

.caption-small {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.caption-small-bold {
    font-size: 12px;
    font-weight: 700;
    line-height: 24px;
}

input[type="text"], input[type="password"], input[type="email"], select, .dropdown-container {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-gray-24);
    border: 1px solid var(--color-gray-2);
    border-radius: 8px;
    height: 56px;
    padding: 8px 16px;
    width: 100%;
    box-sizing: border-box;
}

.input-focus, select {
    /*padding: 24px 16px 8px 16px !important;*/
}

.mobile-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 768px) {
    .mobile-content {
        padding: 0 24px;
    }
}

.margin0 {
    margin: 0 auto;
}

.full-width {
    width: 100% !important;
}

.form-description {
    margin-bottom: 3rem;
    font-size: 14px;
}

form .input, form .phone-number-container {
    margin: 10px auto 25px;
}

.form-items-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 24px;
}

.form-button-container {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-items: center;
}

.relative {
    position: relative;
}

.center {
    text-align: center;
}

.loader {
    border: 4px solid #f3f3f3; /* Light grey */
    border-top: 4px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.form-loader {
    margin: 30px auto 0;
}

button {
    border: none;
    font-family: Roboto, sans-serif;
}

.logo {
    width: 300px;
    height: 42px;
    background: url("./images/logo.png") no-repeat center;
    background-size: contain;
}

.logo-small {
    width: 168px;
    height: 145px;
    background: url("./images/logo-small.png") no-repeat center;
    background-size: contain;
}

.link {
    color: var(--color-blue-5);
    cursor: pointer;
}

.phone-number-container {
    display: flex;
    gap: 8px;
}

.phone-number-container > select {
    width: 106px;
}

.react-calendar {
    position: absolute;
    z-index: 1000;
}

.block {
    border-radius: 16px;
    background: var(--color-white);
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
}

.block-mobile {
    border-radius: 16px;
    background: var(--color-white);
    padding: 12px 16px;
    /*box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);*/
}

.block-big {
    padding: 17px;
}

.block-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    color: var(--color-gray-24);
}

@media screen and (max-width: 768px) {
    .block-title {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
        color: var(--color-gray-24);
    }
}

.no-data {
    color: var(--color-gray-7)
}

.align-right {
    text-align: right;
}

.button-divider {
    width: 1px;
    height: 60px;
    background: var(--color-gray-2);
}

.copy-container {
    background: var(--color-white);
    border: 1px solid var(--color-gray-2);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    font-size: 14px;
    color: var(--color-gray-15);
}

.copy-container > div:nth-child(1) {
    overflow: scroll;
}

.error {
    color: var(--color-red-5);
    font-size: 14px;
}

.success {
    color: var(--color-green-5);
    font-size: 14px;
}

.no-shadow {
    box-shadow: none !important;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0 0 0 20px;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.mx-0-auto {
    margin: 0 auto !important;
}

.items-center {
    align-items: center;
}

.flex {
    display: flex;
}

.column {
    flex-direction: column;
}

.flex-1 {
    flex: 1 1 0;
}

.plr-0-5 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.plr-8 {
    padding-left: 8rem;
    padding-right: 8rem;
}

.plr-2 {
    padding-left: 2rem;
    padding-right: 2rem;
}

.pt-2 {
    padding-top: 2rem;
}

.ptb-0-5 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.gap-0-5 {
    gap: 0.5rem;
}

.gap-0-625 {
    gap: 0.625rem;
}

.gap-1-38 {
    gap: 1.38rem;
}

.gap-1-5 {
    gap: 1.5rem;
}

.gap-2-5 {
    gap: 2.5rem;
}

.gap-2-8 {
    gap: 2.8rem;
}

.gap-3-8 {
    gap: 3.8rem;
}

.gap-4-5 {
    gap: 4.5rem;
}

.gap-5 {
    gap: 5rem;
}

.gap-85px {
    gap: 85px;
}

.gap-6-8 {
    gap: 6.8rem;
}

.gap-1 {
    gap: 1rem;
}

.gap-2 {
    gap: 2rem;
}

.gap-10px {
    gap: 10px;
}

.gap-1-25 {
    gap: 1.25rem;
}

.gap-23 {
    gap: 23px;
}

.w-10 {
    width: 10rem;
}

.h-100vh {
    min-height: 100vh;
}

.h-full {
    height: 100%;
}

.w-full {
    width: 100%;
}

.mw-43 {
    max-width: 43rem;
}

.mw-17-85 {
    max-width: 17.85rem;
}

.w-62-5 {
    width: 62.5rem;
}

.ml-1-5 {
    margin-left: 1.5rem;
}

.ml-5 {
    margin-left: 5rem;
}

.mb-1-42 {
    margin-bottom: 1.42rem;
}

.mb-1-625 {
    margin-bottom: 1.625rem;
}

.mb-2-5 {
    margin-bottom: 2.5rem !important;
}

.mb-3-57 {
    margin-bottom: 3.57rem;
}

.mt-1{
    margin-top: 1rem;
}

.mt-1-625 {
    margin-top: 1.625rem;
}

.mt-1-625 {
    margin-top: 1.625rem;
}

.mt-1n{
    margin-top: -1rem !important;
}


.sb {
    justify-content: space-between;
}

.jsc {
    justify-content: center;
}

.jsr {
    justify-content: right;
}

.cp {
    cursor: pointer;
}

.fs-1-2 {
    font-size: 1.2rem;
}

.grid {
    display: grid;
}

.no-wrap {
    white-space: nowrap;
}

.fs-3 {
    font-size: 3rem;
}

.fs-4 {
    font-size: 4rem;
}

.fs-1-125 {
    font-size: 1.125rem;
}

.fs-1-428 {
    font-size: 1.428rem;
}

.fs-1-3 {
    font-size: 1.3rem;
}

.fs-1-5 {
    font-size: 1.5rem;
}

.fs-1-85 {
    font-size: 1.85rem;
}

.fs-0-8125 {
    font-size: 0.8125rem;
}

.fs-0-875 {
    font-size: 0.875rem;
}

.fs-2-5 {
    font-size: 2.5rem;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-700 {
    font-weight: 700;
}

.ln-1-25 {
   line-height: 1.25rem;
}

.color-gray-24 {
    color: var(--color-gray-24);
}

.max-w-78 {
    max-width: 78rem;
}

.max-h-35 {
    max-height: 35rem;
}

.ls-0-42 {
    letter-spacing: 0.42px;
}

.ls-0-6 {
    letter-spacing: 0.6px;
}

.ls-0-78 {
    letter-spacing: 0.78px;
}

.ls-2 {
    letter-spacing: 2px;
}

.lh-82 {
    line-height: 82px;
}

.lh-24 {
    line-height: 24px;
}

.fit-content {
    width: fit-content;
}

.list-style-disc {
    list-style: disc;
}

.rotate-right-90 {
    transform: rotate(90deg);
}

.rotate-180 {
    transform: rotate(180deg);
}

.contain {
    object-fit: contain
}

.grid-column-2-1fr {
    grid-template-columns: repeat(2, 1fr);
}

.grid-column-2-auto {
    display: grid;
    grid-template-columns: minmax(10px, auto) minmax(10px, 1fr)
}

.a {
    font-variant-numeric: lining-nums proportional-nums;
    font-weight: 300;
    line-height: 32px;
    font-size: 20px;
    letter-spacing: 0.6px;
}

.t {
    max-width: 767px;
}