.select-dropdown {
    position: absolute;
    background: var(--color-white);
    width: 100%;
    min-width: 225px;
    max-height: 400px;
    max-width: 100%;
    border-radius: 16px;
    border: 1px solid var(--color-gray-4);
    z-index: 10000;
    padding: 20px 10px;
    top: 56px;
    right: 0;
    transition: all .25s ease-in-out;
    box-shadow: 0 4px 24px rgba(68, 74, 109, 0.25);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    overflow: scroll;
}

.select-dropdown-item {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
}

.select-dropdown-item:hover {
    color: var(--color-blue-6);
    background: var(--color-blue-1);
}

.select-dropdown-item > svg {
    max-width: 30px;
    max-height: 30px;
}

.select-item-not-found {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    color: var(--color-gray-12)
}

.select-item-not-found > svg {
    width: 40px;
    height: 40px;
}

.select-item-not-found > svg, .select-item-not-found > svg > g > path {
    fill: var(--color-gray-15);
}

.select-dropdown input {
    height: 48px;
    /*padding: 4px 8px !important;*/
    margin-bottom: 10px;
}

.select-dropdown .input-label {
    top: -16px !important;
}