.modal-transfer-request {
    width: 430px;
}

.modal-request-transfer-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-request-transfer-icon svg {
    width: 100px;
    height: 100px;
}

.modal-transfer-info-title {
    color: var(--color-gray-8);
    font-weight: 500;
}

.modal-transfer-info-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: var(--color-gray-1);
    border-radius: 8px;
    padding: 15px;
    margin-top: 5px;
    overflow: auto;
}

.modal-transfer-info-block-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    text-wrap: nowrap;
}

.modal-transfer-info-block-item > div:nth-child(1) {
    color: var(--color-gray-12);
}

.modal-transfer-info-block-item > div:nth-child(2) {
    color: var(--color-gray-15);
    font-weight: 500;
}