.guest-body {
    height: 100%;
    background: var(--color-white);
}
.guest-container {
    display: flex;
    height: 100%;
    min-height: 100%;
    color: var(--color-gray-17);
    background: var(--color-gray-0);
}

.guest-left-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 590px;
    max-width: 45%;
    padding: 77px 100px;
    height: 100%;
    min-height: 100%;
    background: linear-gradient(#1A0E63, #5F727A);
}

@media screen and (max-width: 1024px)  {
    .guest-left-panel {
        padding: 77px 24px;
    }
}

.guest-left-panel-text {
    margin-top: 77px;
    margin-bottom: 87px;
    font-size: 28px;
    color: var(--color-gray-2);
    text-align: center;
}

.guest-cards {
    width: 350px;
    height: 201px;
    background: url("../../../images/cards.svg") no-repeat;
    background-size: contain;
}

.guest-right-panel {
    padding: 77px 112px 77px 117px;
    margin: 0 auto;
    max-width: 55%;
    overflow-x: scroll;
}

@media screen and (max-width: 1024px)  {
    .guest-right-panel {
        padding: 77px 24px 77px 24px;
    }
}

@media screen and (max-width: 768px)  {
    .guest-right-panel {
        padding: 0 24px 44px 24px;
        width: 100%;
        max-width: 100%;
    }
}
