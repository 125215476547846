.modal-send {
    width: 430px;
}

.modal-send-tabs {
    display: flex;
    align-items: center;
    color: var(--color-gray-15);
    gap: 10px;
    margin-bottom: 20px;
}

.modal-send-tabs-item {
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 8px;
}

.modal-send-tabs-item-active {
    background: var(--color-gray-2);
    color: var(--color-gray-24);
    font-weight: 500;
}

.modal-send-no-accounts {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

}

.modal-send-no-accounts > svg {
    width: 60px;
    height: 60px;
}

.modal-send-no-accounts > svg, .modal-send-no-accounts > svg > g > path {
    fill: var(--color-gray-8);
}

.modal-send-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
}

.modal-send-icon svg {
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
}

.modal-send-icon-details {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: 500;
}

.modal-send-icon-details svg {
    width: 100px;
    height: 100px;
}

.modal-transfer-info-title {
    color: var(--color-gray-8);
    font-weight: 500;
}

.modal-transfer-info-block {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background: var(--color-gray-1);
    border-radius: 8px;
    padding: 15px;
    margin-top: 5px;
    overflow: auto;
}

.modal-transfer-info-block-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;
    text-wrap: nowrap;
}

.modal-transfer-info-block-item > div:nth-child(1) {
    color: var(--color-gray-12);
}

.modal-transfer-info-block-item > div:nth-child(2) {
    color: var(--color-gray-15);
    font-weight: 500;
}