.search-user-dropdown {
    position: absolute;
    background: var(--color-gray-2);
    width: 225px;
    max-width: 100%;
    border-radius: 16px;
    border: 1px solid var(--color-gray-4);
    z-index: 10000;
    padding: 20px 10px;
    top: 56px;
    right: 0;
    transition: all .25s ease-in-out;
    box-shadow: 0 4px 24px rgba(68, 74, 109, 0.25);
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    overflow: scroll;
}

.search-user-dropdown-item {
    height: 40px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 10px;
    border-radius: 5px;
    cursor: pointer;
}

.search-user-dropdown-item:hover {
    color: var(--color-blue-6);
    background: var(--color-blue-1);
}

.search-user-not-found {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    color: var(--color-gray-12)
}

.search-user-not-found > svg {
    width: 40px;
    height: 40px;
}

.search-user-not-found > svg, .search-user-not-found > svg > g > path {
    fill: var(--color-gray-15);
}