.kyc-steps {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
}

.kyc-step-item {
    display: flex;
    align-items: center;
    gap: 23px;
}

.kyc-step-title-done {
    color: var(--color-green-5);
}

.kyc-step-title-next {
    color: var(--color-gray-7);
}
