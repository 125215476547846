.mobile-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 45px;
    border-radius: 16px 16px 0 0;
    background: var(--color-white);
    height: 56px;
    bottom: 0;
    position: fixed;
    top: auto;
    width: 100%;
}

.mobile-footer-item-active path, .mobile-footer-item-active > g > path {
    stroke: #4C70D0;
}

.mobile-footer > svg {
    width: 32px;
    height: 32px;
}
