.drawer-bg {
    background-color: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    z-index: 100;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.drawer {
    position: absolute;
    top: 0;
    left: -280px;
    width: 280px;
    height: 100%;
    overflow: hidden;
    max-width: 80%;
    color: var(--color-gray-24);
    background: var(--color-gray-0);
    border-radius: 0 16px 0 0;
    -webkit-transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -o-transition: all .25s ease-in-out;
    transition: all .25s ease-in-out;
    z-index: 100001;
}

.drawer-enabled {
    /*width: 280px;*/
    left: 0;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    box-shadow: 0px 8px 8px 6px rgba(68, 74, 109, 0.4);
}

.drawer-header {
    height: 150px;
    display: grid;
    grid-template-rows: minmax(24px, auto) minmax(50px, auto);
    padding: 10px;
    overflow: hidden;
    color: var(--color-white);
    background: linear-gradient(#1A0E63, #5F727A);
}

.drawer-header-close-container {
    text-align: right;
}

.drawer-header-close-container > svg > path {
    stroke: var(--color-white);
}

.drawer-header-email {
    font-size: 12px;
}

.drawer-menu {
    margin-top: 20px;
}

.drawer-menu-item {
    height: 50px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    gap: 15px;
    font-size: 16px;
}

.drawer-menu-item:hover {
    color: var(--color-white);
    background: var(--color-blue-4);
}

.drawer-menu-item > svg {
    width: 20px;
    height: 20px;
}

.drawer-menu-item > svg, .drawer-menu-item > svg > g > path{
    fill: var(--color-gray-24);
}

.drawer-menu-item:hover > svg, .drawer-menu-item:hover > svg > path, .drawer-menu-item:hover > svg > g > path{
    stroke: var(--color-white);
}