.input {
    width: 100%;
    position: relative;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.input-label {
    position: absolute;
    color: var(--color-gray-7);
    top: -20px;
    left: 8px;
    font-size: 12px;
    transition: all 1s;
}

.input-subtext {
    color: var(--color-gray-8);
    font-weight: 400;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 16px;
}

.input-error {
    border: 1px solid var(--color-red-5) !important;
    outline: none;
}

.input-error-message {
    color: var(--color-red-5);
    font-weight: 400;
    font-size: 12px;
    margin-top: 4px;
    margin-left: 16px;
    position: absolute;
}

.input-link {
    position: absolute;
    right: 16px;
    top: 38px;
}

.icon-eye-open {
    background: url("../../images/icons/eye-open.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 21px;
}

.icon-eye-closed {
    background: url("../../images/icons/eye-closed.png") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 21px;
}

.icon-search {
    background: url("../../images/icons/search/search.svg") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
}

.icon-euro {
    background: url("../../images/icons/currency/eur.svg") no-repeat;
    background-size: contain;
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
}

.icon-copy {
    background: url("../../images/icons/copy.svg") no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 22px;
}

.icon-select-arrow {
    background: url("../../images/icons/input/select-arrow.svg") no-repeat;
    background-size: contain;
    width: 16px;
    height: 16px;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 22px;
}

.content-icon {
    width: 24px;
    height: 24px;
    left: 16px;
    top: 50%;
    transform: translate(0px, -50%);
    cursor: pointer;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content-icon svg {
    max-height: 24px;
    max-width: 24px;
}

.contain-content-icon {
    padding-left: 45px !important;
}

.contain-right-icon {
    padding-right: 40px !important;
}
