.kyc-description {
    color: var(--color-gray-24);
    margin-top: 26px;
}

.kyc-form {
    display: flex;
    column-gap: 40px;
    row-gap: 30px;
    flex-wrap: wrap;
    margin-top: 40px;
}

@media screen and (max-width: 768px) {
    .kyc-form {
        row-gap: 0;
    }
}

.kyc-form-input {
    margin: 0 auto 0;
}

.kyc-form > .input, .kyc-form-input {
    max-width: 355px;
    width: 100%;
    position: relative;
}

.kyc-mobile-title {
    color: var(--color-gray-24);
}

.kyc-left-data {
    margin-top: 40px;
}

.kyc-left-data-table {
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    row-gap: 18px;
    flex-direction: column;
}

.kyc-left-data-table-item {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.kyc-left-data-table-item > div:nth-child(1) {
    width: 30%;
}

.kyc-left-data-list {
    margin-top: 10px;
    display: flex;
    row-gap: 18px;
    flex-direction: column;
}

.kyc-load-file {
    color: var(--color-gray-12);
    border: 1px dashed var(--color-gray-4);
    background: #F8FAFB;
    width: 100%;
    height: 283px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kyc-load-file-active {
    border: 4px dashed var(--color-blue-4);
}

.kyc-load-file-active img {
    max-height: 200px;
    max-width: 100%;
    margin-bottom: 10px;
}


.kyc-load-file > label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.kyc-load-file-filename {
    text-align: center;
}

.kyc-completed-img {
    width: 156px;
    height: 156px;
    margin: 0 auto 36px;
    background: url("../../../../images/icons/kyc/kyc-completed.png") no-repeat center;
    background-size: contain;
}

.kyc-completed-mobile {
    text-align: center;
}

.kyc-completed-mobile-description {
    color: var(--color-gray-12);
    margin-top: 10px;
}

.kyc-mobile-step0 {
    margin-top: 30px;
}

.kyc-full-modal-button-container {
    margin-top: 36px;
    display: flex;
    align-items: center;
    gap: 24px;
    width: 100%;
    justify-content: flex-end;
}

.dob {
    margin-top: 0px !important;
}