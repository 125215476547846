.mobile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
    margin-bottom: 24px;
    width: 100%;
}
.mobile-header-icon {
    position: absolute;
    left: 10px;
}

.mobile-header-icon > svg {
    width: 16px;
    height: 16px;
}

.mobile-header-title {
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
    text-align: center;
    color: var(--color-gray-24);
}